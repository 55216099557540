@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  width: 728px;
  margin: 0 auto;
  max-width: 100%;
}

.buttons {
  display: flex;
  margin-top: 30px;
  margin-bottom: 60px;
  flex-direction: column;
}

.button {
  border: 0;
  padding: 0;
  display: flex;
  cursor: pointer;
  text-align: left;
  position: relative;
  background-color: transparent;

  .content {
    flex: 1;
    overflow: hidden;
    border-radius: 8px;
    padding: 14px 16px;
    position: relative;
    background-color: $color-gray-200;
    transition: padding-left 100ms linear, background-color 100ms linear;

    &:after {
      transition: width 100ms linear;
      width: 0px;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      position: absolute;
      background: $color-g-aurora;
    }
  }

  .info {
    @include font-defaults;
    font-size: rem(12px);
    line-height: rem(14px);
    color: $color-p-white;
    letter-spacing: 0.02em;

    top: 8px;
    right: 8px;
    padding: 5px 7px;
    position: absolute;
    border-radius: 8px;
    background-color: $color-gray-500;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &:hover:not(.disabled) {
    .content {
      padding-left: 32px;
      background-color: #484848;

      &:after {
        width: 16px;
      }

      & .info {
        background-color: $color-gray-700;
      }
    }
  }

  &.disabled {
    .content {
      opacity: 0.4;
      padding-right: 50px;
      background-color: $color-gray-200;
    }
  }

  &.active,
  &.active:hover {
    .content {
      padding-left: 32px;
      background-color: $color-p-white;

      &:after {
        width: 16px;
      }
    }

    p {
      color: $color-p-black;
    }

    small {
      color: $color-gray-80;
    }
  }

  p {
    @include font-defaults;
    color: $color-p-white;
    margin-bottom: 4px;
    font-weight: 500;
    font-size: rem(18px);
    line-height: rem(18px);
    letter-spacing: 0.02em;
  }

  small {
    @include font-defaults;
    font-size: rem(14px);
    line-height: rem(18px);
    letter-spacing: 0.02em;
    color: $color-gray-30;
  }

  .iconLock {
    top: 0;
    right: 16px;
    height: 100%;
    position: absolute;
    align-items: center;
    color: $color-p-white;
  }
}
