@import 'scala/src/styles/variables.scss';

.footer {
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: absolute;

  display: flex;
  align-items: center;
  border-radius: 12px 12px 0 0;
  justify-content: space-between;
  background-color: $color-gray-700;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.4);

  @media (max-width: $grid-breakpoint-small-max) {
    height: 80px;
    padding: 0 16px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    height: 96px;
    padding: 0 24px;
  }

  @media (min-width: $grid-breakpoint-large-min) {
    padding: 0 24px;
  }
}

.tracks {
  display: flex;

  @media (max-width: $grid-breakpoint-medium-max) {
    display: none;
  }
}

.actions {
  display: flex;

  @media (max-width: $grid-breakpoint-medium-max) {
    width: 100%;
    justify-content: space-between;
  }
}

.button {
  @media (max-width: $grid-breakpoint-small-max) {
    width: 163px;
    height: 48px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 229px;
    height: 48px;
  }

  @media (min-width: $grid-breakpoint-large-min) {
    width: 212px;
    height: 36px;
  }
}

.iconNext,
.iconPrevious {
  display: flex;
}

.iconNext {
  transform: rotate(180deg);
}
