@import '../../styles/variables.scss';

@mixin skeleton_animation {
  background-size: 400% 400%;
  animation: skeleton_animate 2s linear infinite;
  -webkit-animation: skeleton_animate 2s linear infinite;
}

@keyframes skeleton_animate {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes loading-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
 * Loading Default
 */

.loadingDefault {
  width: 64px;
  height: 64px;
  display: flex;
  margin: 0 auto;
  position: relative;

  div {
    animation: loading-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }

  div::after {
    content: ' ';
    display: block;
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #00dae8;
    margin: -3px 0 0 -3px;
  }

  div:nth-child(1) {
    animation-delay: -0.036s;
  }

  div:nth-child(1)::after {
    top: 50px;
    left: 50px;
  }

  div:nth-child(2) {
    animation-delay: -0.072s;
  }

  div:nth-child(2)::after {
    top: 54px;
    left: 45px;
  }

  div:nth-child(3) {
    animation-delay: -0.108s;
  }

  div:nth-child(3)::after {
    top: 57px;
    left: 39px;
  }

  div:nth-child(4) {
    animation-delay: -0.144s;
  }

  div:nth-child(4)::after {
    top: 58px;
    left: 32px;
  }

  div:nth-child(5) {
    animation-delay: -0.18s;
  }

  div:nth-child(5)::after {
    top: 57px;
    left: 25px;
  }

  div:nth-child(6) {
    animation-delay: -0.216s;
  }

  div:nth-child(6)::after {
    top: 54px;
    left: 19px;
  }

  div:nth-child(7) {
    animation-delay: -0.252s;
  }

  div:nth-child(7)::after {
    top: 50px;
    left: 14px;
  }

  div:nth-child(8) {
    animation-delay: -0.288s;
  }

  div:nth-child(8)::after {
    top: 45px;
    left: 10px;
  }
}

/*
 * Sidebar Skeleton
 */

.sidebar {
  width: 100%;
  display: flex;
  margin: 0 auto;
  position: relative;
  flex-direction: column;
  max-width: $grid-breakpoint-small-max;

  .sidebarLg {
    height: 40px;
    border-radius: 40px;
    background: linear-gradient(90deg, #333 4.69%, #060606 120%);

    @include skeleton_animation;
  }

  .sidebarXs {
    width: 71px;
    height: 16px;
    margin-top: 39px;
    margin-bottom: 17px;
    border-radius: 8px;
    background: linear-gradient(90deg, #333 4.69%, #111 100%);

    @include skeleton_animation;
  }

  .sidebarLine {
    display: flex;
    margin-bottom: 8px;
    align-items: center;

    @include skeleton_animation;
  }

  .sidebarLineXs {
    width: 34px;
    height: 34px;
    border-radius: 8px;
    background: linear-gradient(90deg, #333 4.69%, #111 100%);

    @include skeleton_animation;
  }

  .sidebarLineLg {
    width: 70%;
    height: 22px;
    margin-left: 5%;
    border-radius: 8px;
    background: linear-gradient(90deg, #333 4.69%, #111 100%);

    @include skeleton_animation;
  }
}

/*
 * Library V2 Skeleton
 */

.libraryV2 {
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  .libraryV2TopLine {
    display: flex;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;

    @media (min-width: $grid-breakpoint-medium-min) {
      flex-direction: column;
    }

    .libraryV2TopLineMd {
      height: 40px;
      border-radius: 40px;
      background: linear-gradient(90deg, #333 4.69%, #060606 120%);

      @include skeleton_animation;

      @media (max-width: $grid-breakpoint-small-max) {
        width: 150px;
      }

      @media (min-width: $grid-breakpoint-medium-min) {
        width: 200px;

        &:last-child {
          align-self: flex-end;
        }
      }
    }
  }

  .libraryV2Line {
    display: flex;
    margin-top: 12px;
    align-items: center;
    border-radius: 12px;
    padding: 15px 10px 15px;
    background: linear-gradient(
      89.82deg,
      #111 0.18%,
      rgba(20, 20, 20, 0.5) 65.61%
    );

    @include skeleton_animation;

    @media (min-width: $grid-breakpoint-medium-min) {
      padding: 15px 20px 15px;
    }

    .libraryV2LineXs {
      width: 24px;
      height: 22px;
      flex: 0 0 24px;
      border-radius: 8px;
      background: linear-gradient(90deg, #333 4.69%, #1b1b1b 120%);

      @include skeleton_animation;

      @media (min-width: $grid-breakpoint-medium-min) {
        width: 34px;
        height: 34px;
        flex: 0 0 34px;
      }
    }

    .libraryV2LineLg {
      display: none;
      width: 70%;
      height: 22px;
      margin-left: 30px;
      border-radius: 8px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      @media (min-width: $grid-breakpoint-medium-min) {
        display: block;
      }
    }

    .libraryV2LineMin {
      width: 80%;
      flex: 0 0 80%;
      height: 22px;
      border-radius: 8px;
      background: linear-gradient(90deg, #333 4.69%, #1b1b1b 120%);

      @include skeleton_animation;

      @media (max-width: $grid-breakpoint-small-max) {
        margin-left: 12px;
      }

      @media (min-width: $grid-breakpoint-medium-min) {
        width: 49px;
        flex: 0 0 49px;
        margin-left: 8%;
      }
    }
  }
}

/*
 * Library Skeleton
 */

.library {
  width: 280px;
  display: flex;
  margin: 0 auto;
  position: relative;
  flex-direction: column;

  @media (min-width: 640px) {
    width: 600px;
  }

  @media (min-width: 850px) {
    width: 800px;
  }
}

.libraryTopLine {
  display: flex;
  justify-content: space-between;

  @media (min-width: 640px) {
    padding-left: 20px;
    padding-right: 25px;
    margin-bottom: 20px;
  }

  .libraryXs {
    width: 100px;
    height: 50px;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;

    @media (min-width: $grid-breakpoint-medium-min) {
      width: 161px;
      height: 50px;
    }
  }

  .libraryMd {
    width: 156px;
    height: 50px;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;

    @media (min-width: $grid-breakpoint-medium-min) {
      width: 252px;
      height: 50px;
    }
  }
}

.libraryLine {
  display: flex;
  margin-top: 40px;
  padding-left: 15px;
  padding-right: 20px;
  align-items: center;
  justify-content: space-around;

  @media (min-width: 640px) {
    padding-left: 20px;
    padding-right: 45px;
  }

  &.useBackground {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 12px;
    background: linear-gradient(
      89.82deg,
      #111 0.18%,
      rgba(20, 20, 20, 0.5) 65.61%
    );

    @include skeleton_animation;
  }

  .libraryXs {
    width: 24px;
    height: 22px;
    flex: 0 0 24px;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;

    @media (min-width: 640px) {
      width: 34px;
      height: 34px;
      flex: 0 0 34px;
    }
  }

  .libraryMd {
    width: 110px;
    height: 22px;
    margin-left: 7%;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;

    @media (min-width: 640px) {
      width: 94px;
    }
  }

  .libraryLg {
    display: none;
    width: 395px;
    height: 22px;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;

    @media (min-width: 640px) {
      display: block;
      margin-left: 6%;
      margin-right: 3%;
    }

    @media (min-width: 850px) {
      margin-left: 6%;
      margin-right: 20%;
    }
  }

  .libraryMin {
    width: 25px;
    height: 22px;
    flex: 0 0 25px;
    margin-left: 12px;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;
  }
}

/*
 * Profile Skeleton
 */
.profile {
  gap: 12px;
  height: 70px;
  display: flex;
  align-items: center;
}

.profileAvatar {
  width: 44px;
  height: 44px;
  flex: 0 0 44px;
  border-radius: 50%;
  background-image: linear-gradient(90deg, #333 4.69%, #1b1b1b 120%);
  @include skeleton_animation;
}

.profileFill {
  width: 130px;
  height: 16px;
  border-radius: 8px;
  background: linear-gradient(90deg, #333 4.69%, #1b1b1b 120%);
  @include skeleton_animation;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

/*
 * Track Modified
 */

.trackModified {
  .title {
    width: 240px;
    height: 32px;
    display: block;
    margin-bottom: 70px;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;
  }

  .header {
    display: flex;
    justify-content: space-between;

    @media (max-width: $grid-breakpoint-small-max) {
      flex-direction: column;
    }

    .col {
      display: flex;
      align-items: center;

      &:last-child {
        @media (max-width: 700px) {
          display: none;
        }
      }
    }

    .avatar {
      display: block;
      width: 64px;
      height: 64px;
      border-radius: 64px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillLarge {
      display: block;
      width: 221px;
      height: 25px;
      margin-left: 25px;
      border-radius: 8px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillSmall {
      display: block;
      width: 94px;
      height: 36px;
      border-radius: 8px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillMedium {
      display: block;
      width: 212px;
      height: 36px;
      margin-left: 25px;
      border-radius: 8px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }
  }

  .switch {
    display: flex;
    margin-top: 50px;
    justify-content: center;

    .fill {
      display: block;
      width: 252px;
      height: 40px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      border-radius: 40px;
    }
  }

  .content {
    display: flex;
    margin-top: 50px;
    justify-content: center;

    .fill {
      display: block;
      height: 100px;
      width: 100%;
      max-width: 1050px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      border-radius: 12px;
    }
  }

  .footer {
    display: flex;
    margin-top: 50px;
    justify-content: space-between;

    @media (max-width: $grid-breakpoint-small-max) {
      flex-direction: column;
    }

    .col {
      display: flex;
      flex-direction: column;

      @media (max-width: $grid-breakpoint-small-max) {
        flex-direction: row;
      }
    }

    .columns {
      display: flex;

      @media (max-width: $grid-breakpoint-small-max) {
        flex-direction: column;
      }

      .col {
        @media (max-width: $grid-breakpoint-small-max) {
          display: flex;
          margin-top: 25px;
        }

        @media (min-width: $grid-breakpoint-medium-min) {
          margin-left: 30px;
        }
      }
    }

    .fillLarge {
      display: block;
      width: 212px;
      height: 25px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      border-radius: 8px;

      @media (max-width: $grid-breakpoint-small-max) {
        width: 150px;
        margin-left: 25px;
      }
    }

    .fillSmall {
      display: block;
      width: 32px;
      height: 25px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      border-radius: 8px;

      @media (max-width: $grid-breakpoint-small-max) {
        margin-left: 25px;
      }
    }

    .fillMedium {
      display: block;
      width: 98px;
      height: 8px;
      margin-bottom: 15px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      border-radius: 8px;
    }
  }
}

/*
 * Track Split
 */

.trackSplit {
  .title {
    width: 240px;
    height: 32px;
    display: block;
    margin-bottom: 70px;
    border-radius: 8px;
    background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

    @include skeleton_animation;
  }

  .header {
    display: flex;
    justify-content: space-between;

    @media (max-width: $grid-breakpoint-small-max) {
      flex-direction: column;
    }

    .col {
      display: flex;
      align-items: center;

      &:last-child {
        @media (max-width: 700px) {
          display: none;
        }
      }
    }

    .avatar {
      display: block;
      width: 64px;
      height: 64px;
      border-radius: 64px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillLarge {
      display: block;
      width: 221px;
      height: 25px;
      margin-left: 25px;
      border-radius: 8px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillSmall {
      display: block;
      width: 94px;
      height: 36px;
      border-radius: 8px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillMedium {
      display: block;
      width: 212px;
      height: 36px;
      margin-left: 25px;
      border-radius: 8px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }
  }

  .content {
    display: flex;
    margin-top: 50px;
    flex-direction: column;

    .fill {
      display: block;
      width: 100%;
      height: 62px;
      margin-bottom: 10px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      border-radius: 8px;
    }
  }
}

/*
 * Playlist
 */

.playlist {
  .create {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;

    .fillLarge {
      display: block;
      width: 156px;
      height: 156px;
      margin-bottom: 8px;
      border-radius: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillMedium {
      display: block;
      width: 337px;
      height: 33px;
      margin-bottom: 8px;
      border-radius: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillSmall {
      display: block;
      width: 289px;
      height: 19px;
      margin-bottom: 8px;
      border-radius: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }
  }

  .line2 {
    margin-top: 40px;
    align-items: center;
    justify-content: center;
  }

  .line {
    justify-content: space-between;
  }

  .line,
  .line2 {
    display: flex;

    @media (max-width: $grid-breakpoint-small-max) {
      flex-direction: column;
    }

    .col {
      display: flex;
      align-items: center;

      &:last-child {
        @media (max-width: 700px) {
          display: none;
        }
      }
    }

    .fillLarge {
      display: block;
      width: 261px;
      height: 40px;
      margin-bottom: 8px;
      border-radius: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillSmall {
      display: block;
      width: 174px;
      height: 19px;
      border-radius: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillMedium {
      display: block;
      width: 196px;
      height: 40px;
      margin-bottom: 8px;
      border-radius: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }

    .fillThin {
      display: block;
      width: 266px;
      height: 22px;
      margin-right: 16px;
      margin-bottom: 8px;
      border-radius: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;
    }
  }

  .content {
    display: flex;
    margin-top: 32px;
    flex-direction: column;

    .fillTrack {
      display: block;
      width: 100%;
      height: 53px;
      margin-bottom: 12px;
      background: linear-gradient(90deg, #262626 4.69%, #0d0d0d 100%);

      @include skeleton_animation;

      border-radius: 12px;
    }
  }
}
