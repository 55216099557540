@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  gap: 20px;
  display: flex;
  cursor: pointer;
  padding: 20px 13px;
  border-radius: 10px;
  flex-direction: column;
  background: linear-gradient(
    332deg,
    #1e1e1e -5.85%,
    #202020 42.77%,
    #4e4e4e 107.99%
  );

  left: -12px;
  bottom: 68px;
  position: absolute;
  width: calc(100% + 12px);
}

.button {
  top: 10px;
  right: 5px;
  position: absolute;

  opacity: 0.5;
  cursor: pointer;
  color: $color-p-white-50A;

  border: 0;
  outline: none;
  background-color: transparent;
}

.image {
  display: flex;
  align-items: center;

  height: 22px;
  width: max-content;

  padding: 0 12px;
  border-radius: 100px;
  background: $color-p-white-20A;
}

.label {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  font-size: 14px;
  color: $color-p-white-50A;
}

.labelBig {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  font-size: 40px;
  line-height: 1;
  color: $color-p-white-50A;
}

.labelSmall {
  @include font-defaults-new;
  @include font-defaults-new-normal;
  font-size: 10px;
  letter-spacing: 2px;
  color: $color-p-white-50A;
  text-transform: uppercase;

  top: -1px;
  position: relative;
}

.clock {
  display: flex;
  text-align: center;
  justify-content: space-between;

  .column {
    flex: 1;
    position: relative;
  }
}

@keyframes tick {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

.colon {
  top: 2px;
  right: -4px;
  font-size: 32px;
  position: absolute;
  animation: tick 1s steps(1, end) 0s infinite;
}
