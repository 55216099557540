@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.empty {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.buttonBig {
  margin-top: 24px;
  padding: 5px 20px !important;
}

.buttonSmall {
  padding: 5px 0 !important;
}

.header {
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;

  .title {
    @include font-defaults;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $color-gray-80;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
}

.list {
  margin-top: 10px;
}
