@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  @media (max-width: $grid-breakpoint-small-max) {
  }

  @media (min-width: $grid-breakpoint-medium-min) {
  }
}

.button {
  width: 100%;
}

.icon {
  color: $color-gray-80;
}

.iconSignIn {
  transform: rotate(180deg);
}

.input {
  margin-bottom: 15px;
}

.label,
.labelError {
  @include font-defaults;
  font-size: rem(12px);
  line-height: rem(14px);
  letter-spacing: 0.02em;
}

.label {
  color: $color-gray-80;
}

.labelError {
  color: $color-s-error;
}
