.content {
  overflow: hidden;
  max-height: 0px;
  visibility: hidden;
  transition: max-height 0.3s ease-in-out, visibility 1s linear;
  -o-transition: max-height 0.3s ease-in-out, visibility 1s linear;
  -moz-transition: max-height 0.3s ease-in-out, visibility 1s linear;
  -webkit-transition: max-height 0.3s ease-in-out, visibility 1s linear;

  &.show {
    max-height: 1000px;
    visibility: initial;
  }
}
