@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.header {
  display: flex;

  @media (max-width: $grid-breakpoint-medium-max) {
    margin-top: 20px;
    flex-direction: column;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    justify-content: space-between;
  }
}

.emptySpan {
  flex: 2;

  @media (max-width: $grid-breakpoint-medium-max) {
    display: none;
  }
}

/**
 * Bar header
 */
.bar {
  flex: 2;
  display: flex;

  @media (max-width: $grid-breakpoint-small-max) {
    width: 100%;
  }
}

// .beta {
//   @include font-defaults;
//   top: -3px;
//   border-radius: 2px;
//   position: relative;
//   padding: 0 2px 0 3px;
//   color: $color-p-black;
//   background-color: rgb(255, 255, 255, 0.3);

//   font-weight: 500;
//   font-size: rem(10px);
//   line-height: rem(12px);
//   letter-spacing: 0.08em;
//   text-transform: uppercase;
// }

/**
 * Steps
 */
.nav {
  display: flex;
  flex: 0 0 auto;
  justify-content: center;

  max-width: 100%;
  margin: 30px auto 0 auto;
  padding: 0 20px 30px 20px;
}

.navItem {
  display: flex;
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
  position: relative;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border-width: 2px;
  border-style: dashed;
  border-color: $color-gray-80;
  background-color: $color-p-black;

  &:not(:last-child) {
    margin-right: 75px;

    &::after {
      content: '';
      width: 80px;
      top: calc(50% - 2px);
      left: calc(100% + 2px);
      position: absolute;
      border-top-width: 2px;
      border-top-style: dashed;
      border-top-color: $color-gray-80;
    }
  }

  &.active {
    border-style: solid;
    border-color: $color-p-white;
  }

  &.check {
    border-style: solid;
    border-color: $color-mint-500;

    &::after {
      border-top-style: solid;
      border-top-color: $color-mint-500;
    }
  }
}

.icon {
  right: -5px;
  bottom: -4px;
  position: absolute;
  color: $color-p-black;
  display: none !important;

  &.show {
    display: flex !important;
  }
}

.index {
  @include font-defaults;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: $color-gray-80;

  &.active {
    color: $color-p-white;
  }

  &.check {
    color: $color-mint-500;
  }
}

.titleStep {
  @include font-defaults;
  top: 47px;
  left: -38px;
  width: 110px;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  position: absolute;
  color: $color-gray-80;

  @media (max-width: $grid-breakpoint-small-max) {
    white-space: nowrap;
    text-overflow: ellipsis;

    &:not(.active) {
      display: none;
    }
  }

  &.active {
    color: $color-p-white;
  }

  &.check {
    color: $color-mint-500;
  }
}
