@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  min-height: 56px;
  padding: 0 0 0 0;
  position: relative;
  align-items: center;
  border-radius: 12px;
  justify-content: space-between;
  background-color: $color-gray-800;

  @media (min-width: $grid-breakpoint-medium-min) {
    &:hover {
      background-color: $color-gray-300;

      .button {
        cursor: pointer;
      }

      .more {
        display: flex !important;
      }

      .type,
      .iconReorder {
        display: flex;
        color: $color-gray-80;
      }

      .title::after {
        background-image: linear-gradient(
          90deg,
          rgba(2, 0, 36, 0) 0%,
          rgba(51, 51, 51, 1) 80%
        );
      }
    }
  }

  &:not(.isFailed, .isQueued, .isProcessing) {
    .icon {
      color: $color-gray-80;
    }
  }

  &.isProcessing {
    .button {
      cursor: default;
    }
  }

  &.isFailed {
    .button {
      cursor: default;
    }

    .icon {
      color: $color-s-error;
    }
  }
}

.info {
  flex: 1;
  display: flex;

  @media (max-width: $grid-breakpoint-small-max) {
    flex-direction: column;
    grid-template-columns: 1fr !important;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    align-items: center;
  }

  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
  padding-right: 50px;
}

.title {
  @include font-defaults;
  text-align: left;
  color: $color-p-white;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;

  flex: 4;
  height: 20px;
  padding-top: 1px;
  overflow: hidden;
  position: relative;

  span {
    height: 20px;
    display: inline-block;
  }

  @media (max-width: $grid-breakpoint-small-max) {
    span {
      @include force-word-wrap(1);
    }
  }

  &::after {
    content: '';
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    position: absolute;
    background-image: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(23, 23, 23, 1) 80%
    );
  }
}

.button {
  flex: 4 1;
  padding: 0;
  border: none;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  flex-direction: row;
  position: relative;
  background-color: transparent;

  @media (min-width: $grid-breakpoint-medium-min) {
    padding-right: 50px;
  }
}

.iconReorder {
  top: 50%;
  left: 5px;
  display: none;
  transform: translateY(-50%);
  position: absolute;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

.actions {
  min-width: 48px;
}

.more {
  @media (min-width: $grid-breakpoint-medium-min) {
    display: none;
  }

  /* Show button for mobile/tablet device */
  @media (hover: none) {
    display: flex;
  }
}
