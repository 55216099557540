@import '../../styles/utilities.scss';
@import '../../styles/typography.scss';
@import '../../styles/variables.scss';

.tooltip {
  display: flex;
  max-width: 300px;
  padding: 10px 16px;
  position: relative;
  border-radius: 12px;
  flex-direction: column;

  &.light {
    background: $color-p-white;
    box-shadow: 0 0 4px -1px $color-p-black-70A;
    color: $color-p-black;
  }

  &.dark {
    background: $color-gray-900;
    box-shadow: 0 2px 8px $color-p-black-90A;
    color: $color-p-white;

    p {
      color: $color-p-white;
    }
  }
}

.title {
  @include font-defaults;

  z-index: 2;
  font-weight: 500;
  text-align: center;
  font-size: rem(16px);
  line-height: rem(16px);
  letter-spacing: 0.02em;
}

.description {
  @include font-defaults;
  // z-index: 2;
  margin-top: 8px;
  text-align: center;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;
}

.tip {
  z-index: 0;
  position: absolute;

  &.top {
    bottom: -8px;
    left: calc(50% - 12px);
  }

  &.left {
    left: -16px;
    top: calc(50% - 4px);
    transform: rotate(90deg);
  }

  &.right {
    right: -16px;
    top: calc(50% - 4px);
    transform: rotate(270deg);
  }

  &.bottom {
    top: -8px;
    left: calc(50% - 12px);
    transform: rotate(180deg);
  }

  &.bottomRight {
    bottom: -8px;
    left: calc(87% - 12px);
  }

  &.topRight {
    top: -7px;
    right: calc(20% - 12px);
    transform: rotate(180deg);
  }
}

.wrapper {
  position: relative;

  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transition-delay: 0.4s;
    }
  }

  .tooltip {
    visibility: hidden;
    position: absolute;
    top: -16px;
    left: 1px;
    padding: 2px 12px;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;

    .label {
      @include font-defaults;
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
    }
  }
}
