.react-inputs-validation__msg___pxv8o {
  margin-top: 5px;
  font-size: 12px;
  padding-left: 0px;
  color: #ff3860;
}

.react-inputs-validation__error___2aXSp input {
  border: 1px solid #ff3860 !important;
  background-color: #ffeded;
}
