@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.modal {
  width: 100%;
  min-width: 328px;
  overflow: hidden;
  position: relative;
  border-radius: 15px;
  background-color: #262626;
  box-shadow: 0px 0px 12px rgba(106, 106, 106, 0.4);

  button {
    z-index: 2;
    position: relative;
  }
}

.content {
  @include font-defaults;
  display: flex;
  line-height: 1.5;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  &.hasImageHighlight {
    padding-top: 80px;
  }
}

.image {
  text-align: center;
  margin-bottom: 6px;
}

.imageHighlight {
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  position: absolute;
}

.title {
  @include font-defaults;
  max-width: 280px;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(20px);
}

.description {
  @include font-defaults;
  color: $color-gray-30;
  margin-top: 16px;
  max-width: 280px;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;
}

.link {
  color: $color-acqua-500;
}

.actions {
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;

  .button {
    width: 100%;
    height: 36px;

    p:first-letter {
      text-transform: uppercase;
    }
  }
}
