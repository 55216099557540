@import 'scala/src/styles/variables.scss';

.container {
  margin-top: 25px;
  border-radius: 8px;
  background: $color-gray-700;
  padding: 20px 16px 28px 20px;

  > * {
    &:not(:last-child) {
      margin-bottom: 42px;
    }
  }
}
