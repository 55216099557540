@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  flex: 1;
  border: 0;
  padding: 0;
  display: flex;
  cursor: pointer;
  background: none;
  position: relative;

  &:hover {
    .button {
      background-color: #a6faff !important;
    }
  }

  @media (max-width: $grid-breakpoint-small-max) {
    margin: 10px 0 16px 0;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin: 50px 12px 0 12px;
  }
}

.content {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  border-radius: 12px;
  flex-direction: column;

  @media (max-width: $grid-breakpoint-small-max) {
    padding: 20px 12px 12px 12px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 20px 24px 12px 24px;
  }

  &:not(.active) {
    color: $color-p-white;
    background-color: $color-gray-500;
  }

  &.active {
    color: $color-p-black;
    background-color: $color-p-white;

    &:before {
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      height: 8px;
      content: '';
      position: absolute;
      background: $color-g-aurora;
    }

    .priceDetail {
      color: $color-gray-80;
    }
  }
}

.headline {
  @include font-defaults;
  font-weight: 500;
  padding: 12px 24px;
  color: $color-p-black;
  background-color: $color-gray-10;

  left: 0;
  right: 0;
  top: -42px;
  z-index: 2;
  position: absolute;
  border-radius: 10px 10px 0 0;

  @media (max-width: $grid-breakpoint-small-max) {
    top: -35px;
    font-size: 18px;
    line-height: 24px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: 24px;
    line-height: 28px;
  }
}

.labelDiscount {
  @include font-defaults;
  font-weight: 500;
  line-height: 14px;
  border-radius: 50%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background-color: $color-mint-500;
  color: $color-p-black;

  z-index: 3;
  position: absolute;

  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: 12px;
    width: 64px;
    height: 64px;
    top: -15px;
    right: -15px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: 14px;
    width: 88px;
    height: 88px;
    top: -25px;
    right: -25px;
  }

  &.big {
    @media (max-width: $grid-breakpoint-small-max) {
      top: -43px;
      font-size: 16px;
      line-height: 1.1;
    }

    @media (min-width: $grid-breakpoint-medium-min) {
      top: -55px;
      font-size: 22px;
      line-height: 1.1;
    }
  }
}

.title {
  @include font-defaults;
  font-weight: 500;
  font-size: rem(14px);
  line-height: rem(16px);
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.price {
  @include font-defaults;
  font-size: 36px;
  font-weight: 500;
  line-height: 40px;
  position: relative;
  width: max-content;
  margin: 8px auto 0 auto;

  .label {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    position: absolute;
    top: 5px;
    left: 105%;
  }

  .regularPrice {
    @include font-defaults;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: $color-gray-80;
    text-decoration: line-through;

    bottom: 5px;
    right: 105%;
    position: absolute;
  }
}

.priceDetail {
  @include font-defaults;
  margin-top: 11px;
  color: $color-gray-30;
  letter-spacing: 0.02em;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: 16px;
    line-height: 20px;
  }
}

.description {
  @include font-defaults;
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  color: $color-p-black-45A;
}

.button {
  width: 100%;
  margin-top: 16px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.icon {
  transform: rotate(180deg);
}
