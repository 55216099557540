@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

$custom-grid-breakpoint-small-max: 1029px;
$custom-grid-breakpoint-medium-min: 1030px;

.header {
  @include font-defaults;

  height: calc(70px + 40px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: $color-gray-700;
  position: -webkit-sticky;
  position: sticky;
  z-index: 9;
  top: 0;
  box-shadow: 0 -1px 8px rgb(0 0 0 / 40%);
}

.container {
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media (min-width: $custom-grid-breakpoint-medium-min) {
    padding-left: 24px;
    padding-right: 8px;
  }
}

.logo {
  flex: 0.5;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: $custom-grid-breakpoint-small-max) {
    order: 2;
    justify-content: center;
  }
}

.button {
  border: 0;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: transparent;
}

.link {
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
}

.logoMoises {
  @media (max-width: $custom-grid-breakpoint-small-max) {
    height: 30px;
  }

  @media (min-width: $custom-grid-breakpoint-medium-min) {
    height: 18px;
  }
}

.nav {
  flex: 0.5;
  display: flex;

  @media (max-width: $custom-grid-breakpoint-small-max) {
    order: 1;
  }

  @media (min-width: $custom-grid-breakpoint-medium-min) {
    justify-content: center;
  }
}

.loading {
  @media (min-width: $custom-grid-breakpoint-medium-min) {
    padding-right: 16px;
  }
}

.actions {
  flex: 0.5;
  display: flex;
  justify-content: flex-end;

  @media (max-width: $custom-grid-breakpoint-small-max) {
    order: 3;
    margin-left: auto;
    margin-right: 15px;
  }
}
