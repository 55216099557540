@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.modal {
  width: 100%;
  min-width: 330px;
  position: relative;
  border-radius: 15px;
  background-color: #262626;
}

.content {
  @include font-defaults;
  display: flex;
  line-height: 1.5;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  &.hideClose {
    padding-top: 24px;
  }
}

.heading {
  @include font-defaults;
  max-width: 280px;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(20px);
  margin-bottom: 16px;
}

.info {
  @include font-defaults;
  max-width: 280px;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;
}

.actions {
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-direction: column;

  .button {
    &.cancel {
      margin-top: 12px;
      color: $color-s-error !important;
    }
  }
}
