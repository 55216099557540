@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/variables.scss';
@import 'scala/src/styles/utilities.scss';

.close {
  position: absolute;
  top: 24px;
  right: 20px;
  cursor: pointer;
  background-color: transparent;
  color: $color-p-white;
  border: none;
}

.commandsList {
  @include font-defaults;
  padding: 24px;
  background-color: $color-gray-700;
  width: 450px;
  overflow: auto;
  line-height: rem(16.8px);
  border-radius: 16px;

  .title {
    color: $color-p-white;
    padding: 0 0 24px 0;

    font-weight: bold;
    font-size: rem(18px);
    line-height: rem(20px);
    border-bottom: solid 1px $color-gray-500;
  }

  .listContext {
    padding-top: 24px;

    .contextName {
      display: block;
      color: $color-gray-80;
      font-weight: 500;
      font-size: rem(14px);
      text-transform: uppercase;
      margin-bottom: 13px;
    }

    .item {
      display: flex;
      font-weight: 500;
      font-size: rem(14px);
      justify-content: space-between;
      color: $color-p-white;
      margin-bottom: 13px;
    }
  }
}
