@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  padding: 0 12px;
  flex-direction: column;
  background-color: $color-gray-900;

  /* fixed */
  top: 0;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  @include custom-scroll;

  &:not(.collapsed) {
    width: 240px;
    flex: 0 0 240px;
  }

  &.collapsed {
    width: 64px;
    flex: 0 0 64px;
  }

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

.logo {
  top: 0;
  z-index: 1;
  position: sticky;
  padding-top: 32px;
  background-color: $color-gray-900 !important;

  @media (max-height: 650px) {
    padding-top: 24px;
  }
}

.content {
  flex: 1;
  height: 460px;
  display: flex;
  flex-direction: column;
}

.nav {
  margin-top: 30px;
}

.setlists {
  flex: 1;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 32px;
}

.setlistCollapsed {
  display: flex;
  padding-top: 24px;
  align-items: flex-start;
  border-top: 1px solid $color-p-white-20A;
}

.user {
  bottom: 0;
  position: sticky;
  padding-left: 12px;
  background-color: $color-gray-900;

  &.collapsed {
    left: -19px;
    position: relative;

    .loading {
      padding-left: 6px;
    }
  }

  &.extraHeight {
    padding-top: 230px;
  }
}
