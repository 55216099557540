@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.button {
  width: 100%;
  display: flex;
  border-radius: 8px;

  border: 0;
  gap: 12px;
  padding: 8px 12px;
  align-items: center;
  background-color: transparent;

  &:not(.active) {
    cursor: pointer;
    color: $color-gray-40;

    &:hover {
      background-color: rgb(0, 0, 0, 0.16);
    }
  }

  &.active {
    cursor: pointer;
    color: $color-p-white;
    background: #ffffff0d;
  }

  &.disabled {
    cursor: wait;

    &:hover {
      background-color: transparent;
    }
  }
}

.title {
  @include font-defaults-new;
  font-size: 14px;
  text-align: left;
}

.beta {
  @include font-defaults-new;
  font-size: 10px;
  color: $color-p-black;
  text-transform: uppercase;
  background-color: $color-p-white-30A;

  top: -1px;
  padding: 1px 2px;
  margin-left: 3px;
  border-radius: 2px;
  position: relative;
}
