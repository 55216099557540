@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.button {
  border: 0;
  padding: 0;
  width: 100%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  background-color: transparent;
}

.label {
  text-align: left;
}

.title {
  @include font-defaults;
  color: $color-p-white;
  font-size: rem(16px);
  letter-spacing: 0.02em;
}

.description {
  @include font-defaults;
  color: $color-p-white;
  margin-top: 24px;
  font-size: rem(12px);
  line-height: rem(14px);
}
