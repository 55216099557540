@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 4px 0 0 0;
  background-color: transparent;
}

.checkbox {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.active) {
    border-color: $color-gray-80;
  }

  &.active {
    border-color: $color-acqua-500;
    background-color: $color-acqua-500;
  }
}

.content {
  margin-left: 16px;
}

.title {
  @include font-defaults;
  font-size: rem(14px);
  line-height: 120%;
  color: $color-p-white;
}

.description {
  @include font-defaults;
  font-size: rem(12px);
  line-height: 120%;

  margin-top: 2px;
  color: $color-gray-80;
}
