@import '../../../styles/utilities.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/variables.scss';

.container {
  width: 100%;
  border-radius: 8px;
  position: relative;
  color: $color-gray-80;
}

.input {
  @include font-defaults;

  width: 100%;
  height: rem(44px);
  outline: none;
  border-radius: 8px;
  font-size: rem(16px);
  padding: 14px 45px 14px 12px;
  background-color: $color-p-black;
  border: 2px solid $color-gray-200;

  &:not([disabled]) {
    color: $color-p-white;
  }

  &[disabled] {
    color: $color-gray-200;
  }

  &::placeholder {
    color: $color-p-white-50A;
  }

  &.success {
    border-color: $color-s-success;
  }

  &.error {
    border-color: $color-s-error;
  }

  &.hasIcon {
    padding-left: 45px;
  }
}

.inputContent {
  position: relative;
}

.content {
  margin-top: 8px;
}

.icon {
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.button {
  border: 0;
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
}

.iconClose {
  color: $color-p-white-50A;
}

.iconEye {
  color: $color-gray-80;
}
