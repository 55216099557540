@import 'scala/src/styles/variables.scss';

body[data-page='player'],
body[data-page='player2'] {
  .list {
    bottom: 120px;
  }
}

.list {
  z-index: 11;
  position: fixed;

  @media (max-width: $grid-breakpoint-small-max) {
    width: 100%;
    bottom: 20px;
    display: flex;
    padding: 0 16px;
    align-items: center;
    flex-direction: column;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
  }
}

.item {
  margin-top: 4px;
  color: $color-p-black;
}
