@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.loading {
  @media (max-width: $grid-breakpoint-small-max) {
    margin: 33px auto 0;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin: 116px auto 0;
  }
}

.error {
  margin-top: 20vh;
}
