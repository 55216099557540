@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/variables.scss';

.container {
  z-index: 1;
  position: relative;
}

.dropMenu {
  opacity: 0;
  top: -400vh;
  position: absolute;
  flex-direction: column;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;

  min-width: 214px;
  max-width: 214px;
  width: 214px;

  &.show {
    opacity: 1;
  }

  &.right {
    right: 0;
  }

  @media (max-width: $grid-breakpoint-small-max) {
    &.show.left {
      top: 35px;
      left: 5px;
    }

    &.show.right {
      top: 45px;
      right: 0;
    }
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    &.show.left {
      top: 38px;
      left: 24px;
    }

    &.show.left {
      top: 38px;
      right: 0;
    }
  }
}

.button {
  width: 100%;
  padding-left: 12px;
}

.btnUpload {
  &.light {
    padding: 5px 0px !important;
  }
}
