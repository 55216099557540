@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  margin-right: 24px;
  flex-direction: column;
  background-color: $color-gray-700;
}

.title {
  @include font-defaults;
  font-weight: 500;
  font-size: rem(10px);
  line-height: rem(12px);
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.info {
  width: 212px;
  height: 40px;
  display: flex;
  padding: 10px;
  margin-top: 8px;
  border-radius: 8px;
  align-items: center;
  background-color: $color-gray-200;

  p {
    @include font-defaults;
    overflow: hidden;
    margin-left: 10px;
    font-size: rem(14px);
    line-height: rem(18px);
    letter-spacing: 0.02em;
    color: $color-p-white;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
