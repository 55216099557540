@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  height: rem(20px);
  display: flex;
  overflow: hidden;
  position: relative;

  @media (min-width: $grid-breakpoint-medium-min) {
    justify-content: center;
  }

  &.isButton {
    cursor: pointer;
  }

  &::after {
    content: '';
    top: 45%;
    left: 0;
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: $color-gray-500;
  }
}

.title {
  @include font-defaults;
  z-index: 1;
  color: $color-p-white;
  font-weight: 500;
  font-size: rem(14px);
  padding-right: 20px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background-color: $color-p-black;

  @media (min-width: $grid-breakpoint-medium-min) {
    padding-left: 20px;
  }
}

.icon {
  z-index: 1;
  top: -2px;
  left: -8px;
  width: 24px;
  height: 24px;
  position: relative;
  background-color: $color-p-black;

  &.active {
    transform: rotate(180deg);
  }

  &::after {
    content: '';
    top: 0;
    right: 24px;
    height: 24px;
    width: 10px;
    position: absolute;
    background-color: $color-p-black;
  }

  &::before {
    content: '';
    top: 0;
    left: 24px;
    height: 24px;
    width: 10px;
    position: absolute;
    background-color: $color-p-black;
  }
}
