@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.labelBig {
  @include font-defaults;
  font-weight: 500;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: rem(18px);
    line-height: rem(20px);
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: rem(24px);
    line-height: rem(28px);
  }
}

.select {
  @include font-defaults;
  margin-top: 20px;
  width: 300px;
  max-width: 100%;
  border-radius: 8px;
  font-size: rem(16px);
  line-height: rem(20px);
  color: $color-p-white;
  padding: 10px 40px 10px 8px;
  border: 1.5px solid $color-gray-200;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE4IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMOSA3TDE3IDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==)
    no-repeat right 10px center;

  option {
    color: $color-p-white;
    background: $color-p-black;
  }
}

.list {
  @include font-defaults;
  letter-spacing: 0.02em;
  color: $color-gray-60;
  padding-left: 20px;
  margin-top: 20px;

  li {
    list-style-type: disc;
  }

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: rem(16px);
    line-height: rem(20px);
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: rem(18px);
    line-height: rem(24px);
  }
}
