@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.progress {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  @include font-defaults;
  margin-top: 15px;
  font-weight: 500;
  font-size: rem(24px);
  line-height: rem(28px);
}

.description {
  @include font-defaults;
  margin-top: 12px;
  font-weight: 400;
  text-align: center;
  font-size: rem(16px);
  color: $color-gray-30;
  line-height: rem(20px);
  letter-spacing: 0.02em;
}

.button {
  margin-top: 20px;
}

.progressError {
  width: 160px;
  height: 160px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  color: $color-s-error;
  justify-content: center;
  border: 8px solid $color-gray-200;
}
