@font-face {
  font-family: 'Geomanist';
  src: url('/assets/fonts/geomanist-regular-webfont.eot');
  src: url('/assets/fonts/geomanist-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/geomanist-regular-webfont.woff2') format('woff2'),
    url('/assets/fonts/geomanist-regular-webfont.woff') format('woff'),
    url('/assets/fonts/geomanist-regular-webfont.ttf') format('truetype'),
    url('/assets/fonts/geomanist-regular-webfont.svg#geomanistregular')
      format('svg');
  font-weight: 400;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/fonts/geomanist-bold-webfont.eot');
  src: url('/assets/fonts/geomanist-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/geomanist-bold-webfont.woff2') format('woff2'),
    url('/assets/fonts/geomanist-bold-webfont.woff') format('woff'),
    url('/assets/fonts/geomanist-bold-webfont.ttf') format('truetype'),
    url('/assets/fonts/geomanist-bold-webfont.svg#geomanistregular')
      format('svg');
  font-weight: bold;
}

@font-face {
  font-family: 'Geomanist';
  src: url('/assets/fonts/geomanist-medium-webfont.eot');
  src: url('/assets/fonts/geomanist-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('/assets/fonts/geomanist-medium-webfont.woff2') format('woff2'),
    url('/assets/fonts/geomanist-medium-webfont.woff') format('woff'),
    url('/assets/fonts/geomanist-medium-webfont.ttf') format('truetype'),
    url('/assets/fonts/geomanist-medium-webfont.svg#geomanistregular')
      format('svg');
  font-weight: 500;
}

@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-DemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-Medium.otf');
  font-weight: 550;
}

@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Articulat';
  src: url('/assets/fonts/ArticulatCF-Normal.otf');
  font-weight: 400;
}

$font-family: geomanist, helvetica, arial, sans-serif;

@mixin font-defaults {
  font-size: 16px;
  font-weight: 400;
  font-family: $font-family;
}

@mixin font-defaults-new {
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.2px;
  font-family: 'Articulat', helvetica, arial, sans-serif;
}

@mixin font-defaults-new-normal {
  font-weight: 400;
}

@mixin font-defaults-new-medium {
  font-weight: 550;
}
