@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  height: 40px;
  display: flex;
}

.button {
  border: 0;
  opacity: 0.8;
  height: 40px;
  padding: 0 10px;
  color: $color-p-white;
  background-color: transparent;

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.28;
  }

  &.small {
    padding: 0 6px;
  }
}

.iconMore {
  color: $color-p-white;
}

.drop {
  position: relative;

  &.show {
    display: flex;
  }

  &.hideOnDesktop {
    @media (min-width: $grid-breakpoint-medium-min) {
      display: none;
    }
  }
}

.dropMenu {
  top: 35px;
  display: none;
  position: absolute;
  flex-direction: column;
  min-width: 175px !important;
  max-width: 175px !important;

  @media (max-width: $grid-breakpoint-small-max) {
    right: 5px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    &:not(.right) {
      left: 5px;
    }
  }

  &.right {
    right: 5px;
  }

  &.show {
    display: flex;
  }
}

.containerOpened {
  display: none;
  position: relative;

  @media (min-width: $grid-breakpoint-medium-min) {
    &.showOnDesktop {
      display: flex;
    }
  }
}

.buttonOpened {
  margin: 0 20px;
}
