@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: $color-gray-90;

  width: 44px;
  height: 44px;
  flex: 0 0 44px;

  &.active {
    border: 1px solid $color-p-white;
  }
}

.icon {
  color: $color-p-white;
}

.image {
  width: 44px;
  height: 44px;
}
