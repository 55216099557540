@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.description {
  @include font-defaults;
  letter-spacing: 0.02em;
  color: $color-p-white;

  @media (max-width: $grid-breakpoint-small-max) {
    margin-bottom: 34px;
    font-size: rem(14px);
    line-height: rem(18px);
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin-bottom: 40px;
    font-size: rem(18px);
    line-height: rem(24px);
  }
}

.button {
  width: 100%;
  margin-top: 12px;
}

.iconArrowBack {
  transform: rotate(180deg);
}

.labelError {
  @include font-defaults;
  font-size: rem(12px);
  line-height: rem(14px);
  letter-spacing: 0.02em;
  color: $color-s-error;
}
