@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.credits {
  border: 0;
  display: flex;
  margin-top: 5px;
  justify-content: center;
  background-color: transparent;

  @include font-defaults;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;
  color: $color-gray-80;
}

.icon {
  top: 1px;
  cursor: pointer;
  margin-left: 4px;
  position: relative;
  color: $color-gray-80;
}

.switch {
  margin-top: 20px;
}
