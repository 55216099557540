@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

$trackPlaylistMinHeight: 72px;

.container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 12px;
  background-color: $color-gray-800;
  justify-content: space-between;

  background-size: auto 100%;
  background-repeat: no-repeat;
  background-image: url('/assets/images/playlist-line-background.png');
}

.button {
  flex: 4 1;
  padding: 0;
  border: none;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  background-color: transparent;
  min-height: $trackPlaylistMinHeight;
}

.info {
  flex: 4;
  display: flex;
  padding: 10px 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.title {
  @include font-defaults;
  color: $color-p-white;
  text-align: left;
  font-size: rem(14px);
  line-height: rem(18px);
  word-break: break-all;
  letter-spacing: 0.02em;
}

.count {
  @include font-defaults;
  margin-top: 4px;
  color: $color-gray-30;
  font-size: rem(12px);
  line-height: rem(14px);
  letter-spacing: 0.02em;
}

.iconContainer,
.iconShared {
  display: flex;
  align-self: center;
  color: $color-p-white;

  @media (max-width: $grid-breakpoint-small-max) {
    padding: 0 20px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 0 30px;
  }
}

.iconShared {
  opacity: 0.3;
}
