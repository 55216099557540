@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.list {
  height: 40px;
  position: relative;
  align-self: center;
  border-radius: 100px;

  // getting the columns to be equal
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;

  &:not(.dark) {
    background: $color-gray-500;
  }

  &.dark {
    background: $color-gray-900;
  }

  &:after {
    content: '';
    top: 2px;
    left: 2px;
    height: 36px;
    position: absolute;
    border-radius: 100px;
    background: $color-p-white;

    transition: left 0.1s linear, width 0.1s linear;
    -o-transition: left 0.1s linear, width 0.1s linear;
    -moz-transition: left 0.1s linear, width 0.1s linear;
    -webkit-transition: left 0.1s linear, width 0.1s linear;
  }

  &[data-total='1'] {
    &:after {
      width: 100%;
    }
  }

  &[data-total='2'] {
    &:after {
      width: 50%;
    }

    &[data-index='0'] {
      &:after {
        left: 2px;
      }
    }

    &[data-index='1'] {
      &:after {
        left: calc(50% - 2px);
      }
    }
  }

  &[data-total='3'] {
    &:after {
      width: 33%;
    }

    &[data-index='0'] {
      &:after {
        left: 2px;
      }
    }

    &[data-index='1'] {
      &:after {
        left: 33%;
      }
    }

    &[data-index='2'] {
      &:after {
        left: calc(67% - 2px);
      }
    }
  }
}

.item {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  border: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 0 15px;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  @include font-defaults-new;
  @include font-defaults-new-medium;
  font-size: 16px;
  line-height: 16px;
  color: $color-p-white;
  letter-spacing: 0.02em;

  transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  -moz-transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;

  &.active {
    color: $color-p-black;
  }
}

.icon {
  top: -1px;
  left: -5px;
  position: relative;
}
