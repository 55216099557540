@import '../../styles/variables.scss';
@import '../../styles/utilities.scss';
@import '../../styles/typography.scss';

.navUser {
  height: 70px;
  display: flex;
  position: relative;
  color: $color-p-white;
}

.userInfo {
  border: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  background-color: transparent;

  @media (min-width: $grid-breakpoint-medium-min) {
    padding-left: 20px;
    padding-right: 16px;

    &.active,
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .avatar {
      border: 1px solid $color-p-white;
    }
  }
}

.userInfoContainer {
  text-align: right;
  margin-right: 12px;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

.label {
  @include font-defaults;

  font-weight: 500;
  font-size: rem(16px);
  line-height: rem(20px);
  color: $color-p-white;
}

.labelPremium {
  @include font-defaults;

  line-height: 20px;
  font-size: rem(12px);
  color: $color-acqua-500;
}

.buttonPremium {
  color: $color-mint-500 !important;
}

.drop {
  display: flex;
  top: -100vh;
  position: absolute;
  flex-direction: column;
  transition: opacity 0.2s;
  -webkit-transition: opacity 0.2s;

  @media (max-width: $grid-breakpoint-small-max) {
    right: 0;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    right: 16px;
  }

  &:not(.show) {
    opacity: 0;
    visibility: hidden;
  }

  &.show {
    opacity: 1;

    @media (max-width: $grid-breakpoint-small-max) {
      top: 55px;
    }

    @media (min-width: $grid-breakpoint-medium-min) {
      top: 70px;
    }
  }
}
