@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.key {
  @include font-defaults;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: $color-gray-400;
  border-radius: 4px;
  padding: 0 8px;
  color: $color-gray-40;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
}
