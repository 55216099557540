@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  @include font-defaults;
  display: flex;
  justify-content: space-between;

  @media (max-width: $grid-breakpoint-small-max) {
    flex-direction: column;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    align-items: center;

    > * {
      width: 48%;
    }
  }
}

.label {
  color: $color-p-white;
  font-size: rem(16px);
  letter-spacing: 0.02em;

  @media (max-width: $grid-breakpoint-small-max) {
    margin-bottom: 20px;
  }
}

.drop {
  display: flex;
}

.selectContainer {
  width: 100%;
  position: relative;
}

.icon {
  z-index: 1;
  top: 12px;
  right: 15px;
  position: absolute;
  color: $color-gray-80;
}

.select {
  z-index: 2;
  height: 48px;
  cursor: pointer;
  width: inherit;
  color: $color-p-white;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 50px;
  font-size: rem(14px);
  position: relative;
  background-color: transparent;
  border: 2px solid $color-gray-80;

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    right: 15px;
  }

  option {
    font-size: rem(14px);
    background-color: $color-p-black;
  }

  // hide native arrow
  -moz-appearance: none;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }
}

.customValue {
  border: none;
  width: 50px;
  height: 48px;
  margin-left: 12px;
  text-align: center;
  border-radius: 8px;
  font-size: rem(14px);
  background-color: $color-p-white;
}
