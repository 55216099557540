@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.modal {
  max-width: 465px;
  border-radius: 8px;
  background-color: $color-gray-200;
}

.title {
  @include font-defaults;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(20px);
  padding: 0 24px 24px;
}

.content {
  display: flex;
  flex-direction: row;
  padding: 0 24px 24px 24px;
}

.list {
  flex: 1;
}

.itemList {
  @include font-defaults;
  color: $color-gray-30;
  font-size: rem(16px);
  line-height: rem(20px);
  letter-spacing: 0.02em;

  display: flex;
  margin-bottom: 12px;
}

.icon {
  margin-right: 14px;
  color: $color-gray-200;
}

.apps {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.qrCode {
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 8px;
}

.desktopApp {
  margin: 0 24px 24px 24px;
  & .dowloadDesktopApp {
    padding: 4px 15px;
    width: max-content;
    line-height: 24px;
  }
}

.getDesktopApp {
  @include font-defaults;
  font-weight: 500;
  font-size: rem(18px);
  line-height: 20px;
  margin-bottom: 16px;
}

.highlight {
  color: $color-mint-500;
}
