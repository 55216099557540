@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  flex: 1;
  width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 55px;

    .divider {
      height: 1.5px;
      background-color: $color-p-white-25A;
      width: 80px;
      border-radius: 1px;
    }

    .or {
      @include font-defaults-new;
      font-size: 14px;
      line-height: 150%;
      margin: 0 25px;
      color: $color-gray-70;
    }
  }

  .contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -8px;

    .description {
      @include font-defaults-new;
      font-size: 14px;
      line-height: 120%;
      color: white;

      .playlistButton {
        @include font-defaults;
        padding: 0;
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 500;
        border: 0;
        color: white;
        background: transparent;
        cursor: pointer;
        transition: opacity 80ms ease-in-out;

        &:hover {
          opacity: 0.7;
        }

        .icon {
          margin-left: 8px;
          transform: rotate(180deg);
        }
      }
    }

    .hideButton {
      margin: 8px 8px;
      padding: 8px 8px;
      border: 0;
      background: transparent;
      cursor: pointer;
      transition: opacity 80ms ease-in-out;

      svg path {
        stroke: $color-gray-40;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .track {
    margin-top: 6px;
    margin-bottom: 8px;
  }
}
