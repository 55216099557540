@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  padding: 7px 16px;
  align-items: center;
  justify-content: space-between;
  background-color: $color-acqua-500;

  &.hide {
    display: none;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    display: none;
  }
}

.buttonGetApp {
  @include font-defaults;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;
  color: $color-p-black;
  background: transparent;
  text-align: left;
  border: 0;

  flex: 1;
  display: flex;
  align-items: center;

  strong {
    font-weight: 500;
    text-decoration: underline;
  }
}

.icon {
  margin-right: 10px;
}

.button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
}
