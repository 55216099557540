@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  position: relative;
  flex-direction: column;

  max-width: 100%;
  margin-top: 24px;

  padding: 20px 24px;
  border-radius: 12px;
  background-color: $color-mint-500;

  @media (max-width: $grid-breakpoint-small-max) {
    width: 100%;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 300px;
  }
}

.title {
  @include font-defaults;
  font-weight: 500;
  color: $color-p-black;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: rem(16px);
    line-height: rem(24px);
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: rem(18px);
    line-height: rem(20px);
  }
}

.description {
  @include font-defaults;
  color: $color-p-black;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: 0.02em;
  margin-top: 4px;
}

.labelDiscount {
  @include font-defaults;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  color: $color-p-black;

  padding: 6px 16px;
  border-radius: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background-color: $color-p-white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);

  top: -15px;
  right: -10px;
  position: absolute;
}

.svg {
  top: -9px;
  left: 20px;
  position: absolute;
}
