@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.button {
  border: 0;
  display: flex;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.image {
  position: relative;

  &:not(.small) {
    height: 32px;
  }

  &.small {
    left: -3px;
    height: 22px;
    margin: 5px 0 5px 0;
  }
}
