@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  flex-direction: column;
  background-color: $color-gray-500;
}

.title {
  @include font-defaults;
  display: flex;
  position: relative;
  padding: 16px 40px 16px 20px;

  p {
    margin-left: 15px;
    font-size: rem(16px);
    line-height: rem(20px);
    color: $color-mint-500;
    letter-spacing: 0.02em;
  }
}

.iconMusic {
  color: $color-mint-500;
}

.iconClose {
  cursor: pointer;
}

.button {
  top: 0;
  right: 0;
  border: 0;
  padding: 8px;
  position: absolute;
  color: $color-p-white;
  background-color: transparent;
}

.details {
  @include font-defaults;
  display: flex;
  padding: 9px 16px;
  background-color: $color-gray-700;

  p {
    margin-right: 35px;
    font-size: rem(14px);
    line-height: rem(18px);
    color: $color-gray-80;
    letter-spacing: 0.02em;

    span {
      color: $color-p-white;
      text-transform: lowercase;
    }
  }
}
