@import '../../styles/variables.scss';
@import '../../styles/typography.scss';

.container {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;

  @media (max-width: $grid-breakpoint-small-max) {
  }

  @media (min-width: $grid-breakpoint-medium-min) {
  }
}

.sidebar {
}

.content {
  flex: 1;
  display: flex;
  justify-content: center;
}
