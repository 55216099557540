@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.title {
  @include font-defaults-new;
  @include font-defaults-new-medium;
  font-size: 30px;
  line-height: 1.2;
  color: $color-p-white;

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: 40px;
  }
}
