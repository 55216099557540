@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  align-self: center;
  position: relative;
  color: $color-p-white;

  @media (max-width: $grid-breakpoint-small-max) {
    padding: 0 20px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 0 30px;
  }

  &:hover {
    .tooltip {
      display: flex !important;
    }
  }
}

.lottie {
  width: 24px;
  height: 24px;
}

.tooltip {
  left: 50%;
  bottom: 140%;
  transform: translateX(-50%);
  color: $color-p-black;
  display: none !important;
  position: absolute !important;

  p {
    white-space: nowrap;
    font-weight: 400 !important;
  }
}
