@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.modal {
  @media (max-width: $grid-breakpoint-small-max) {
    width: 340px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 420px;
  }
}

.container {
  padding: 24px;
  position: relative;
  border-radius: 16px;
  background: $color-gray-700;
}

.title {
  @include font-defaults;
  font-weight: 500;
  font-size: rem(18px);
  line-height: rem(20px);
  color: $color-p-white;
}

.content {
  margin: 17px 0;
  padding: 17px 0;
  border-top: 1px solid $color-gray-500;
  border-bottom: 1px solid $color-gray-500;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.button {
  height: 36px;
  margin-left: 15px;
}

.input {
  margin-bottom: 10px;

  ::placeholder {
    text-transform: lowercase;
  }
}
