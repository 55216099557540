@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  padding: 0 13px;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @include font-defaults-new;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  color: $color-gray-40;
}

.button {
  @include font-defaults-new;
  font-size: 14px;
  font-weight: 600;
  color: $color-acqua-500;
  letter-spacing: 0.02em;

  border: 0;
  padding: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  background: transparent;

  &:hover {
    opacity: 0.7;
  }
}

.icon {
  color: $color-acqua-500;
}

.list {
  flex: 1;
  display: flex;
  margin-top: 20px;
  padding-bottom: 100px;
  flex-direction: column;
  @include custom-scroll;
}
