@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(.newDesign) {
    background-size: 100%;
    background-image: url('https://storage.googleapis.com/scala-web/assets/images/library-empty.svg');

    @media (max-width: $grid-breakpoint-small-max) {
      padding: 50px 0;
    }

    @media (min-width: $grid-breakpoint-medium-min) {
      height: 30vh;
    }

    &::after {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.9) 75.87%
      );
    }
  }
}

.icon {
  margin: 8px auto;
  width: max-content;
}

.title {
  @include font-defaults;
  z-index: 1;
  text-align: center;
  font-size: rem(18px);
  line-height: rem(22px);
  color: $color-gray-30;

  display: flex;
  align-items: center;
  flex-direction: column;

  strong {
    display: block;
    margin-top: 10px;
    font-weight: 500;
    font-size: rem(32px);
    line-height: rem(32px);
    color: $color-p-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: $grid-breakpoint-small-max) {
      width: 320px;
    }

    @media (min-width: 820px) {
      width: 450px;
    }

    @media (min-width: 1000px) {
      width: 600px;
    }

    @media (min-width: 1200px) {
      width: 750px;
    }
  }

  .button {
    margin-top: 24px;
    padding: 5px 40px;
  }
}

.newDesign {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 72px 0;
  border-radius: 12px;
  background-color: $color-p-white-10A;

  .boxTitle {
    @include font-defaults-new;
    font-size: 18px;
    line-height: 19.44px;
    font-weight: 500;
    color: $color-p-white;
    letter-spacing: 0.7px;
  }

  .content {
    @include font-defaults;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 400;
    color: $color-gray-70;
  }

  .button {
    @include font-defaults-new;
    width: 192px;
    height: 36px;
    padding: 0px 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
  }
}
