@import '../../styles/variables.scss';
@import '../../styles/utilities.scss';
@import '../../styles/typography.scss';

.errorTemplate {
  @include font-defaults;

  color: $color-p-white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: rem(40px);
  font-weight: 500;
  letter-spacing: 0.01em;
}

.message {
  margin-top: 24px;
  font-size: rem(16px);
  letter-spacing: 0.02em;

  @media (max-width: $grid-breakpoint-small-max) {
    max-width: 50%;
    text-align: center;
  }
}

.btnRetry {
  margin-top: 32px;
}
