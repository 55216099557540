@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  flex: 1;
  position: relative;
}

.type {
  @include font-defaults;
  letter-spacing: 0.02em;
  width: max-content;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    margin-top: 4px;
    color: $color-gray-30;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin: 0 auto;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: $color-gray-80;

    &:hover {
      ~ .tooltip {
        display: flex !important;
      }
    }
  }
}

.tooltip {
  z-index: 999;
  left: 50%;
  bottom: 150%;
  color: $color-p-black;
  transform: translateX(-50%);
  display: none !important;
  position: absolute !important;

  p {
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
  }
}
