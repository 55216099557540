@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.item {
  padding: 0;

  > div > button {
    padding-left: 0 !important;
    border-left-color: transparent !important;
  }

  &.canDrop {
    background: $color-gray-700;

    .buttonIcon p::after {
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(29, 29, 29, 1) 90%
      );
    }
  }

  &.isActive {
    background: $color-acqua-500;

    .buttonIcon p::after {
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(0, 218, 232, 1) 90%
      );
    }
  }

  &.isOver {
    background: $color-acqua-500;

    .buttonIcon p::after {
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(0, 218, 232, 1) 90%
      );
    }
  }
}

.button {
  display: flex;

  &:hover {
    .more {
      display: flex !important;
    }
  }
}

.actions {
  width: 12%;
}

.more {
  display: none;
}

.buttonIcon {
  width: 88%;
  height: 40px;

  p {
    font-size: 14px;
  }

  &.isOver {
    color: $color-p-black;
  }
}
