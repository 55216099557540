@use 'sass:math';

/**
 * Convert px to rem
 * base: 16px
 */
@function rem($size) {
  $remSize: math.div($size, 16px);

  @return #{$remSize}rem;
}

@mixin force-word-wrap($quantity) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: $quantity;
  -webkit-line-clamp: $quantity;
  -webkit-box-orient: vertical;
}

@mixin custom-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin; /* For Firefox */

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #212121;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #333;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
}

@mixin force-one-line {
  overflow: hidden;
  white-space: nowrap;
}

@mixin page-without-margin {
  margin: 0;
}

@mixin page-full-content {
  max-width: 100%;
}

@mixin first-letter-uppercase {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}
