@import '../../../styles/utilities.scss';
@import '../../../styles/typography.scss';
@import '../../../styles/variables.scss';

.container {
  width: 100%;
  border-radius: 8px;
  position: relative;
  color: $color-gray-80;
}

.textarea {
  @include font-defaults;

  width: 100%;
  height: 90px;
  min-height: 90px;
  max-height: 90px;
  outline: none;
  resize: none;
  padding: 7px 12px;
  border-radius: 8px;
  font-size: rem(16px);
  background-color: $color-p-black;
  border: 2px solid $color-gray-200;

  &::-webkit-scrollbar {
    width: 3px;
    background-color: $color-gray-500;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $color-gray-200;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }

  &:not([disabled]) {
    color: $color-p-white;
  }

  &[disabled] {
    color: $color-gray-200;
  }

  &::placeholder {
    color: $color-gray-80;
  }

  &.success {
    border-color: $color-s-success;
  }

  &.error {
    border-color: $color-s-error;
  }

  &.hasIcon {
    padding-left: 45px;
  }
}

.textareaContent {
  position: relative;
}

.content {
  margin-top: 8px;
}

.button {
  border: 0;
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  top: 12px;
  right: 5px;
}
