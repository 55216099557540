@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/variables.scss';

.progress {
  position: relative;
}

.percent {
  @include font-defaults;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  font-size: 56px;
  font-weight: 500;
  position: absolute;
  align-items: center;
  color: $color-p-white;
  justify-content: center;

  p {
    line-height: 1em;
    position: relative;
  }

  small {
    top: 4px;
    right: -19px;
    font-size: 22px;
    line-height: 22px;
    color: #bfbfbf;
    position: absolute;
  }
}

.player {
  width: 160px;
  height: 160px;
}
