@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  flex-grow: 1;
  display: flex;
  margin-right: 40px;
  justify-content: flex-end;

  &.disabled {
    .icon,
    .title > p {
      opacity: 0.5;
    }
  }

  &:not(.disabled) {
    cursor: pointer;
  }
}

.content {
  position: relative;
}

.iconContainer {
  position: relative;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }

  &:hover {
    .tooltip {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
}

.icon {
  top: 2px;
  display: inline;
  position: relative;
}

.tooltip {
  width: 308px;
  padding: 16px;
  display: block;
  color: $color-p-black;

  left: 50%;
  top: -15px;
  position: absolute;
  transform: translateX(-50%) translateY(-100%);

  opacity: 0;
  visibility: hidden;
  transition: visibility 0s, opacity 0.2s 0.4s;
}

.title {
  color: $color-gray-30;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;

  display: flex;

  @media (max-width: 400px) {
    font-size: 12px;
  }

  @media (max-width: $grid-breakpoint-small-max) {
    flex-direction: column;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    flex-direction: row;

    > p {
      margin-right: 5px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

.description {
  color: $color-gray-60;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  margin-top: 2px;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}
