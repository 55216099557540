@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;

  @media (min-width: $grid-breakpoint-medium-min) {
    padding-top: 24px;
  }
}

.button {
  border: 0;
  display: flex;
  cursor: pointer;
  margin-right: 5px;
  padding: 0 5px 0 0;
  color: $color-p-white;
  background-color: transparent;

  @media (max-width: $grid-breakpoint-small-max) {
    margin-left: -4px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding-top: 7px;
  }
}

.title {
  @include font-defaults-new;
  font-weight: 600;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: rem(24px);
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: rem(34px);
  }
}

.subtitle {
  @include font-defaults-new;
  opacity: 0.6;
  margin-top: 8px;
  font-size: rem(16px);
  color: $color-p-white;
}
