@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  padding: 50px 0;
  margin-top: 24px;
  position: relative;
  align-items: center;
  justify-content: center;
  background-size: 100%;
  background-image: url('https://storage.googleapis.com/scala-web/assets/images/library-empty.svg');

  &::after {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.9) 75.87%
    );
  }
}

.title {
  @include font-defaults;
  z-index: 1;
  text-align: center;
  font-size: rem(18px);
  line-height: rem(22px);
  color: $color-gray-30;

  strong {
    display: block;
    margin-top: 10px;
    font-weight: 500;
    font-size: rem(32px);
    line-height: rem(35px);
    color: $color-p-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: $grid-breakpoint-small-max) {
      width: 320px;
    }

    @media (min-width: 820px) {
      width: 450px;
    }

    @media (min-width: 1000px) {
      width: 600px;
    }

    @media (min-width: 1200px) {
      width: 750px;
    }
  }
}
