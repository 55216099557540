@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  position: relative;
  border-radius: 8px;
  flex-direction: column;

  &:not(.active) {
    color: $color-p-white;
    background-color: $color-gray-80;
  }

  &.active {
    color: $color-p-black;
    background-color: $color-p-white;
  }

  &:hover {
    .button {
      background-color: #a6faff !important;
    }
  }

  @media (max-width: $grid-breakpoint-small-max) {
    padding: 8px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 16px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  @include font-defaults;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;

  display: flex;
  align-items: center;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: 10px;
    line-height: 12px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: 14px;
    line-height: 16px;
  }
}

.labelDiscount {
  @include font-defaults;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  border-radius: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  background-color: $color-mint-500;
  color: $color-p-black;
  padding: 4px 8px;
  margin-left: 8px;

  @media (max-width: $grid-breakpoint-small-max) {
    display: none;
  }
}

.price {
  @include font-defaults;
  font-weight: 500;
  position: relative;
  width: max-content;
  margin-right: 22px;

  @media (max-width: $grid-breakpoint-small-max) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    font-size: 24px;
    line-height: 28px;
  }

  .label {
    top: 0px;
    left: 105%;
    position: absolute;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }

  .regularPrice {
    @include font-defaults;
    font-weight: 500;
    color: $color-gray-80;
    text-decoration: line-through;

    right: 106%;
    position: absolute;

    @media (max-width: $grid-breakpoint-small-max) {
      bottom: 0px;
      font-size: 10px;
      line-height: 14px;
    }

    @media (min-width: $grid-breakpoint-medium-min) {
      bottom: 3px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.button {
  width: 100%;
  margin-top: 10px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.icon {
  transform: rotate(180deg);
}
