@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  width: 100%;
  margin-top: 30px;
}

.dropzone {
  @include font-defaults;
  display: flex;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: rem(16px);
  line-height: rem(20px);
  color: $color-p-white;
  letter-spacing: 0.02em;
  border-radius: 8px;
  border: 2px dashed $color-gray-80;
  background-color: $color-gray-900;

  @media (max-width: $grid-breakpoint-small-max) {
    padding: 16px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    padding: 34px 28px;
  }

  @media (min-width: $grid-breakpoint-large-min) {
    padding: 26px 24px;
  }
}

.dropzoneHide {
  display: none;
}

.dropzoneActive {
  border-color: $color-mint-500;
}

.dropzoneReject {
  border-color: $color-s-error;
}

.buttonSelect {
  @media (max-width: $grid-breakpoint-small-max) {
    width: 184px;
    padding: 11px 24px !important;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    width: 228px;
    padding: 11px 24px !important;
  }

  @media (min-width: $grid-breakpoint-large-min) {
    display: none !important;
  }
}

.label {
  display: none;

  @media (min-width: $grid-breakpoint-large-min) {
    display: flex;
  }

  span {
    top: 1px;
    left: 4px;
    font-weight: 500;
    position: relative;
    color: $color-acqua-500;
  }
}

.labelSupported {
  @include font-defaults;
  margin-top: 22px;
  font-size: rem(12px);
  line-height: rem(14px);
  color: $color-gray-80;

  @media (min-width: $grid-breakpoint-large-min) {
    margin-top: 10px;
  }
}
