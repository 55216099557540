@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  width: 100%;
  display: flex;
  margin: 0 24px;
  flex-direction: column;
  max-height: $grid-content-height;
  padding-bottom: rem(100px);
}

.main {
  margin-top: 65px;
  overflow: auto;
  @include custom-scroll;
}

.navItem {
  &.show {
    font-weight: 500;
  }
}

.step {
  display: none;

  &.show {
    display: flex;
    flex-direction: column;
  }
}
