@import 'scala/src/styles/typography.scss';
@import 'scala/src/styles/utilities.scss';
@import 'scala/src/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;
}

.title {
  @include font-defaults;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $color-gray-80;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-top: 7px;

  @media (max-width: $grid-breakpoint-small-max) {
    margin-bottom: 20px;
  }

  @media (min-width: $grid-breakpoint-medium-min) {
    margin-bottom: 8px;
  }
}

.track {
  margin-bottom: 8px;
}

.loading {
  width: 100%;
  margin-top: -10px;
}
